import React from 'react'
import './style.less';
import { Layout, Row, Col } from 'antd';
import Link from "../Link";
import TopNav from "../TopNav";

const { Header } = Layout;

class Head extends React.Component {

    render(){

        return (
            <Header>
                <Row type="flex" align="bottom">
                    <Col xs={{span: 12}} md={{span: 5}}>
                        <Link className="logo" to="/" width="120">
                            Storyman
                        </Link>
                    </Col>
                    <TopNav />
                </Row>
            </Header>
        );
    }
}

export default Head
