import React from 'react'
import './style.less';
import { Form, Input, Button } from 'antd';

class SignupForm extends React.Component {

    constructor(props) {
        super(props);
        this.validateFormAndSend = this.validateFormAndSend.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.state = {
            complete: false,
            status: false,
            errors: []
        };
    }

    validateFormAndSend(e){
        e.preventDefault();

        // Validate the form
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.sendMessage(values);
            }
        });
    }

    sendMessage(values){
        return fetch('https://api.brew-systems.co.uk/contact-storyman/', {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                'title': 'New Subscriber',
                'email' : values.email,
            }),
            headers:{
                'Content-Type': 'application/json'
            }
        }).then(res => {
            return res.json();
        }).then(res => {
            if (res.success === true){
                // Clear and hide the form
                this.setState({ complete: true, status: 'success'});
            } else {
                // Display errors
                this.setState({ status: 'error', errors: res.errors});
            }
        }).catch(err => err);
    }

    render(){
        const { getFieldDecorator } = this.props.form;

        if (this.state.complete === false) {
            return (
                <Form layout="inline" className="signup-form">
                    <p>Our blog is designed to keep you updated on industry thoughts, latest campaigns, and stories worth sharing.</p>

                    <Form.Item label="Email">
                        {getFieldDecorator('email', {
                            rules: [{type: "email", message: "Please enter a valid email address"}, {
                                required: true,
                                message: 'Please enter an email address'
                            }],
                            initialValue: ''
                        })(
                            <Input placeholder=""/>
                        )}
                    </Form.Item>

                    <Button className="btn-primary" onClick={this.validateFormAndSend}>Sign up</Button>

                </Form>
            );
        } else {
            return <p>Thanks for your interest, we will be in touch soon.</p>
        }
    }
}

export default Form.create()(SignupForm);
