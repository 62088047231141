import React from "react";
import "./style.less";
import { Col, Menu, Button } from "antd";
import Link from "../Link";

class TopNav extends React.Component {
  state = { open: false };

  openMobileNav = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  render() {
    const isPartiallyActive = ({ isPartiallyCurrent }) => {
      return isPartiallyCurrent ? { className: "activeLink" } : null;
    };

    return (
      <React.Fragment>
        <Col xs={{ span: 12 }} md={{ span: 0 }}>
          <Button
            className="menu-trigger"
            onClick={this.openMobileNav}
            open={this.state.open}
          >
            Menu
            <span className="icon" />
          </Button>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 0 }}>
          <Menu mode="vertical" open={this.state.open}>
            <Menu.Item key="menu-001">
              <Link to="/showreel/" getProps={isPartiallyActive}>
                Showreel
              </Link>
            </Menu.Item>
            <Menu.Item key="menu-006">
              <Link to="/studio/" getProps={isPartiallyActive}>
                Studio
              </Link>
            </Menu.Item>
            <Menu.Item key="menu-002">
              <Link to="/work/" getProps={isPartiallyActive}>
                Work
              </Link>
            </Menu.Item>
            <Menu.Item key="menu-003">
              <Link to="/about/" getProps={isPartiallyActive}>
                About
              </Link>
            </Menu.Item>
            <Menu.Item key="menu-004">
              <Link to="/blog/" getProps={isPartiallyActive}>
                Blog
              </Link>
            </Menu.Item>
            <Menu.Item key="menu-005">
              <Link to="/contact/" getProps={isPartiallyActive}>
                Contact
              </Link>
            </Menu.Item>
          </Menu>
        </Col>
        <Col xs={{ span: 0 }} md={{ span: 19 }}>
          <Menu mode="horizontal">
            <Menu.Item key="menu-001">
              <Link to="/showreel/" getProps={isPartiallyActive}>
                Showreel
              </Link>
            </Menu.Item>
            <Menu.Item key="menu-006">
              <Link to="/studio/" getProps={isPartiallyActive}>
                Studio
              </Link>
            </Menu.Item>
            <Menu.Item key="menu-002">
              <Link to="/work/" getProps={isPartiallyActive}>
                Work
              </Link>
            </Menu.Item>
            <Menu.Item key="menu-003">
              <Link to="/about/" getProps={isPartiallyActive}>
                About
              </Link>
            </Menu.Item>
            <Menu.Item key="menu-004">
              <Link to="/blog/" getProps={isPartiallyActive}>
                Blog
              </Link>
            </Menu.Item>
            <Menu.Item key="menu-005">
              <Link to="/contact/" getProps={isPartiallyActive}>
                Contact
              </Link>
            </Menu.Item>
          </Menu>
        </Col>
      </React.Fragment>
    );
  }
}

export default TopNav;
