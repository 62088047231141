import React from 'react'
import './style.less';
import { Layout, Row, Col } from 'antd';
import Link from "../Link";
import SignupForm from "../SignupForm";

const { Footer } = Layout;

class Foot extends React.Component {

    render(){

        return (
            <Footer>
                <Row type="flex" align="top">
                    <Col xs={{span: 24}} md={{span: 8}} lg={{span:6}}>
                        <Link className="logo" to="/" width="120">
                            Storyman
                        </Link>
                    </Col>
                    <Col xs={{span: 24}} md={{span: 16}} lg={{span:2, push:16}}>
                        <div className="social-links">
                            <Link to="https://vimeo.com/storyman">
                                <img src="/vimeo.png" alt="storyman on Vimeo"/>
                            </Link>
                            <Link to="https://www.instagram.com/storyman_/">
                                <img src="/instagram.png" alt="storyman on Instagram"/>
                            </Link>
                            <Link to="https://uk.linkedin.com/in/storyman-02352b50">
                                <img src="/linkedin.png" alt="storyman on LinkedIn"/>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={{span: 24}} md={{span: 12}} lg={{span:8, pull:2}}>
                        <h6>Get in touch</h6>
                        <p className="email">hello@storyman.co</p>
                        <p className="phone">+44 (0) 207 060 4517</p>
                        <p className="address">Huckletree West, MediaWorks,<br/>
                        191 Wood Lane, White City Place,<br/>
                        London, W12 7TU<br/>
                            <Link to="/" className="arrow-link">Google Maps</Link></p>
                    </Col>
                    <Col xs={{span: 24}} md={{span: 12}} lg={{span:8, pull:2}}>
                        <h6>Never miss a story</h6>
                        <SignupForm />
                    </Col>
                </Row>

                <Row>
                    <Col xs={{span:24}}>
                        <p>Storyman Ltd <i>|</i> Company No. 9832131  <i>|</i>  VAT No. 293649458 <span className="links"><Link to="/privacy-policy/" className="arrow-link">Privacy Policy</Link></span></p>
                    </Col>
                </Row>
            </Footer>
        );
    }
}

export default Foot
