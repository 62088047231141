import React, {Component} from 'react'

import './wrapper.less'
import { Layout } from 'antd';
import MetaData from './MetaData'
import Head from "./Head";
import Foot from "./Foot";

class Wrapper extends Component {
    render() {
        const { children } = this.props;
        return (
            <div className="app">
                <MetaData
                    title={this.props.title}
                    description={this.props.description}
                    og_title={this.props.og_title}
                    og_description={this.props.og_description}
                    og_image={this.props.og_image}
                    twitter_card_type={this.props.twitter_card_type}
                    twitter_title={this.props.twitter_title}
                    twitter_description={this.props.twitter_description}
                    twitter_image={this.props.twitter_image}
                    canonical={this.props.canonical}
                    schema={this.props.schema}
                    path={this.props.path}
                />
                <link href="https://fonts.googleapis.com/css?family=Anton|Montserrat|Poppins:400,500,600&display=swap" rel="stylesheet" />
                <Layout className="layout">
                    <Head/>

                    <div className="mainContent">
                        {children}
                    </div>

                    <Foot/>
                </Layout>
            </div>
        )
    }
}

export default Wrapper
